body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
      "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #F2F3F8;
  }

  .feather {
    width: 16px;
    height: 16px;
    vertical-align: text-bottom;
  }

  .feather .svg-inline--fa {
    width: 30px;
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
      monospace;
  }

  .text-wrap { white-space: normal; }

  .sidebar {
      position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        z-index: 98;
        padding-left: 0;
        padding-right: 0;
        background-color: #242939;
        padding: 48px 0 0; /* Height of navbar */
        box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
      }

      @media (max-width: 767.98px) {
        .sidebar {
          top: 5rem;
        }
      }
      

    .navbar {
      z-index: 97 !important;
    }
  
    /* Sidebar navigation */

    .sidebar-sticky {
      position: relative;
      top: 0;
      height: calc(100vh - 48px);
      padding-top: .5rem;
      overflow-x: hidden;
      overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
    }

    @supports ((position: -webkit-sticky) or (position: sticky)) {
      .sidebar-sticky {
        position: -webkit-sticky;
        position: sticky;
      }
    }
    
    .sidebar .nav-link {
      font-weight: 500;
      color: #fff;
    }
    
    .sidebar .nav-link .feather {
      margin-right: 10px;
      color: #999;
    }
    
    .sidebar .nav-link.active {
      color: #007bff;
    }
    
    .sidebar .nav-link:hover .feather,
    .sidebar .nav-link.active .feather {
      color: inherit;
    }
    
    .sidebar-heading {
      font-size: .75rem;
      text-transform: uppercase;
    }

    .left-panel {
      flex: 0 0 320px;
      left: 0px;
      background: #fff;
      background: var(--bg);
      border-right: 1px solid rgba(0,0,0,.2);
      border-right: 1px solid var(--color-border);
      height: 100%;
      max-height: calc(100vh - 44px);
      display: flex;
      flex-direction: column;
      top: 0;
      left: 0;
      width: 100%;
      overflow: hidden;
      position: absolute;
      will-change: transform;
    }

    .center-panel {
      left: 0px;
    }

    @media (min-width: 641px) {
      .left-panel {
        position: relative;
      }
    }

    .s-alert-box {
      position: fixed;
      background: rgba(42,45,50,0.85);
      padding: 22px;
      line-height: 1.4;
      z-index: 99999 !important;
      pointer-events: none;
      color: rgba(250,251,255,0.95);
      font-size: 100%;
      font-family: 'Helvetica Neue', 'Segoe UI', Helvetica, Arial, sans-serif;
      max-width: 300px;
      -webkit-transition: top .4s, bottom .4s;
      transition: top .4s, bottom .4s;
  }

  .mdi-dots-vertical:after {
    content: '\2807';
    font-size: 25px;
    }
    
pre { display: 'block'; padding: '10px 30px'; margin: '0'; overflow: 'scroll'; white-space: pre-wrap; }

/* pre.body {
  width: 100%;
  display: block;
  max-height: 500px;
  overflow: auto;
  margin: 0;
  padding: 0 5px;
  background-image: -webkit-linear-gradient(#F3F2F2 50%, #EBEAEA 50%);
  background-image:    -moz-linear-gradient(#F3F2F2 50%, #EBEAEA 50%);
  background-image:     -ms-linear-gradient(#F3F2F2 50%, #EBEAEA 50%);
  background-image:      -o-linear-gradient(#F3F2F2 50%, #EBEAEA 50%);
  background-image:         linear-gradient(#F3F2F2 50%, #EBEAEA 50%);
  background-position: 0 0;
  background-repeat: repeat;
  background-attachment: local;
  background-size: 4em 4em;
  line-height: 2em;
  display: none;
} */

  ::-webkit-scrollbar-track {
    border-radius: 0.125rem;
    background-color: lightgray;
  }
  ::-webkit-scrollbar {
    width: 0.25rem;
    border-radius: 0.125rem;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 0.125rem;
    background-color: gray;
  }
